import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import HomePage from '../src/pages/HomePage';
import {Helmet} from "react-helmet";

function App() {
  return (
    <>  
    <Helmet>
      <title>Jesse Granier - Développeur Web</title>
    </Helmet>

    {/* Google Tag Manager */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-MJ6RCPMX2G"></script>
      <script dangerouslySetInnerHTML={{
        __html: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-MJ6RCPMX2G');`
      }} />

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P2B3BN9M"
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
        </iframe>
      </noscript>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} /> 
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
