import '../assets/css/layer/components/header.scss';
import logo_jg from '../assets/images/logo_JG1.png'

const Header = () => {

    const scrollToAnchor = (e, anchorId) => {
        e.preventDefault(); // Empêche le comportement par défaut du lien
        const anchor = document.querySelector(anchorId);
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    return(
        <header className='body_header'>            
                <div className='header_left'>
                    <div>
                        <img src={logo_jg} alt="Jesse Granier, Développeur Web" />
                    </div>
                    <div className='name'>
                        <p><span class="btn">Jesse Granier</span></p>
                        <p className='dev'>Developpeur Web</p>
                    </div>
                </div>
                <div className='nav'>
                    <p><a href="#realisations" onClick={(e) => scrollToAnchor(e, '#realisations')}>Réalisations</a></p>
                    <p><a href="#cv" onClick={(e) => scrollToAnchor(e, '#cv')}>Compétences</a></p>
                    <p><a href="#contact" onClick={(e) => scrollToAnchor(e, '#contact')}>Contact</a></p>
                </div>
        </header>
    )
}
export default Header ;