import '../assets/css/layer/components/footer.scss';

const Footer = () => {
    return (
        <footer className='body_footer'>
            <p>&gt; &copy; <a href="mailto:jesse.devweb@gmail.com?subject=Prise de contact">jesse.devweb@gmail.com</a> | 2024 &lt;</p>
        </footer>
    )
}

export default Footer;