import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../assets/css/layer/pages/homepage.scss';
import couite from "../assets/images/domainedecouite.png";
import precitape from "../assets/images/precitape.png";
import escapegreen from "../assets/images/escapegreen.png";
import traveltogether from "../assets/images/travel_together_492206933.png";
import kidslearning from "../assets/images/kids_learning_866406301.png";
import renovation from "../assets/images/renov_img_723915048.png";
import tinker from "../assets/images/template_tinker_530710055.png";
import cv from "../assets/images/Jesse_Granier_thb.jpg";
import cv_full from "../assets/images/Jesse_Granier.pdf";
import arrow_down from "../assets/images/arrow_down.png";
import arrow_right from "../assets/images/arrow_right.png";
import arrow_left from "../assets/images/arrow_left.png";
import cloud from "../assets/images/cloud_small.png";
import cloud02 from "../assets/images/cloud02_small.png";
import arrow_up_thumb from "../assets/images/arrow_up_thumb.png";
import mail from "../assets/images/mail.png";
import mail2 from "../assets/images/mail2.png";

const HomePage = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    const handleArrowClick = (e) => {
        e.preventDefault();
        scrollToTop();
    };
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseOver = () => {
        setIsHovered(true);
    };
    const handleMouseOut = () => {
        setIsHovered(false);
    };
    

    
    return (
        <div className="body">
            <Header />
            <link rel="icon" type="image/png" href="../assets/images/favicon.ico" />
            <div className="body_home">
                <div className="introduction">
                    <h1>Jesse Granier, un développeur web freelance</h1>
                    <p className="bonjour">Bonjour !</p>
                    <hr className="line" />
                    <p className="description">
                        Bonjour ! Je suis <strong>Jesse Granier</strong>, un <strong>développeur web</strong> freelance passionné.
                        Formé à La Piscine à Mérignac, je suis spécialisé dans le développement
                        fullstack et je propose mes services en HTML, CSS, JavaScript, React, Node.js, etc.
                        Chaque projet représente pour moi une nouvelle aventure. N'hésitez pas à me contacter !
                    </p>
                    <div class="bounce"><img src={cloud} alt="Nuage rebondissant by Jesse Granier" /></div>
                    <p>J'ai réalisé plusieurs projets passionnants, dont certains sont présentés ci-dessous.
                        Je suis également compétent dans l'utilisation d'outils tels que Git et GitHub pour la gestion de versions,
                        Adobe XD pour le maquettage et Visual Studio Code comme mon principal environnement de développement.
                    </p>
                    <div class="swing"><img src={cloud02} alt="Nuage glissant by Jesse Granier" /></div>
                </div>
            </div>
            <div id="realisations" className="container_portfolio">
                <h2>Réalisations</h2>
                <hr className="line_white" />
                <div class="bounce2"><a href="#top" onClick={handleArrowClick}><img src={arrow_up_thumb} alt="Retour en haut de page" className="arrow_up_thumb" /></a></div>
                <div className="portfolio">
                    <div class="class_thumb">
                        <h2>Domaine de Couite</h2>
                        <div className="descrip_small">
                            <h3>Projet Freelance</h3>
                            <p>HTML, CSS, React, JavaScript</p>
                        </div>
                        <a href="https://domainedecouite.com/" target="_blank"><img src={couite} alt="Domaine de Couite, Sauternes - Projet Jesse Granier, développeur web" id="projets" />
                        <h3 class="text">Projet Freelance</h3>
                        <p class="text">HTML, CSS, React, JavaScript</p></a>
                    </div>
                    <div class="class_thumb">
                        <h2>Précitape</h2>
                        <div className="descrip_small">
                            <h3>Projet Personnel</h3>
                            <p>HTML, CSS, React, JavaScript</p>
                        </div>
                        <a href="https://overthere.fr/precitape/" target="_blank"><img src={precitape} alt="Precitape - Projet Jesse Granier, développeur web" id="projets" />
                        <h3 class="text">Projet Personnel</h3>
                        <p class="text">HTML, CSS, React, JavaScript</p></a>
                    </div>
                    <div class="class_thumb">
                        <h2>Hackathon - Escape Green</h2>
                        <div className="descrip_small">
                            <p>React, JavaScript, Node.js, Express.js, SCSS</p>
                        </div>
                        <img src={escapegreen} alt="Escape Green, projet Hackathon 2024 - Projet Jesse Granier, développeur web" id="projets" />
                        <h3 class="text">Hackathon fin de formation</h3>
                        <p class="text">HTML, CSS, React, JavaScript, Node.js, Express.js, SCSS</p>
                    </div>
                    <div class="class_thumb">
                        <h2>Travel Together</h2>
                        <div className="descrip_small">
                            <h3>Projet de formation</h3>
                            <p>HTML, CSS, Bootstrap, SCSS</p>
                        </div>
                        <img src={traveltogether} alt="Travel Together - Projet Jesse Granier, développeur web" id="projets" />
                        <h3 class="text">Projet de formation</h3>
                        <p class="text">HTML, CSS, Bootstrap, SCSS</p>
                    </div>
                    <div class="class_thumb">
                        <h2>Kids Learning</h2>
                        <div className="descrip_small">
                            <h3>Projet de formation</h3>
                            <p>HTML, CSS, Bootstrap, SCSS</p>
                        </div>
                        <img src={kidslearning} alt="Kids Learning - Projet Jesse Granier, développeur web" id="projets" />
                        <h3 class="text">Projet de formation</h3>
                        <p class="text">HTML, CSS, Bootstrap, SCSS</p>
                    </div>
                    <div class="class_thumb">
                        <h2>Espace Rénovation</h2>
                        <div className="descrip_small">
                            <h3>Projet de formation</h3>
                            <p>HTML, CSS, Bootstrap, SCSS</p>
                        </div>
                        <img src={renovation} alt="Espace Rénovation - Projet Jesse Granier, développeur web" id="projets" />
                        <h3 class="text">Projet de formation</h3>
                        <p class="text">HTML, CSS, Bootstrap, SCSS</p>
                    </div>
                    <div class="class_thumb">
                        <h2>Template Tinker</h2>
                        <div className="descrip_small">
                            <h3>Projet de formation</h3>
                            <p>HTML, CSS, Bootstrap, SCSS</p>
                        </div>
                        <img src={tinker} alt="Template Tinker - Projet Jesse Granier, développeur web" id="projets" />
                        <h3 class="text">Projet de formation</h3>
                        <p class="text">HTML, CSS, Bootstrap, SCSS</p>
                    </div>
                </div>
            </div>
            <div id="cv" className="cv">
                <div>
                    <h2>Curriculum Vitae</h2>
                    <hr className="line" />
                    <div class="bounce2"><a href="#top" onClick={handleArrowClick}><img src={arrow_up_thumb} alt="Retour en haut de page" className="arrow_up_thumb" /></a></div>
                    <div className="cv_and_arrows">
                        <div className="arrow_left">
                            <img src={arrow_left} alt="flèche de gauche animée" />
                        </div>
                        <div className="shake">
                            <a href={cv_full} target="_blank"><img src={cv} alt="Curriculum Vitae Jesse Granier, développeur web front-end back-end full stack" className="cv_thumb" /></a>
                        </div>
                        <div className="arrow_right">
                            <img src={arrow_right} alt="fleche de droite animée" />
                        </div>
                    </div>
                </div>
                <img src={arrow_down} alt="flèche en dessous animée" className="arrow_bottom"/>
            </div>
            <div id="contact" className="contact">
                <h2>Contactez-moi</h2>
                <hr className="line" />
                <div>
                    <p>Vous avez besoin d'un site web ? Une question ? N'hésitez pas à me contacter, je serai ravi de vous aider !</p>
                    <a href="mailto:jesse.devweb@gmail.com?subject=Prise de contact">
                        <img src={isHovered ? mail2 : mail} alt="Jesse Granier, Développeur web en attente de vos demandes !" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
                    </a>
                </div>
                <div class="bounce2"><a href="#top" onClick={handleArrowClick}><img src={arrow_up_thumb} alt="Retour en haut de page" className="arrow_up_thumb" /></a></div>
            </div>
            <Footer />
        </div>
    )
}

export default HomePage;